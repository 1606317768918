<template>
  <!--begin::Card-->
  <div class="col-md-4">
    <h3 class="mb-10">Branding</h3>
    <b-input-group class="mb-5">
      <template v-slot:prepend>
        <b-input-group-text >Primary Color</b-input-group-text>
      </template>
      <b-form-input
      v-model="properties.style.primary_color"
      class="form-control form-control-lg"
      type="color"
      ></b-form-input>
    </b-input-group>
    <b-input-group class="mb-5">
      <template v-slot:prepend>
        <b-input-group-text >Secondary Color</b-input-group-text>
      </template>
      <b-form-input
      v-model="properties.style.secondary_color"
      class="form-control form-control-lg"
      type="color"
      ></b-form-input>
    </b-input-group>
    <b-input-group class="mb-5">
      <template v-slot:prepend>
        <b-input-group-text >Primary Button Background</b-input-group-text>
      </template>
      <b-form-input
      v-model="properties.style.primary_button_bg"
      class="form-control form-control-lg"
      type="color"
      ></b-form-input>
    </b-input-group>
    <b-input-group class="mb-5">
      <template v-slot:prepend>
        <b-input-group-text >Secondary Button Background</b-input-group-text>
      </template>
      <b-form-input
      v-model="properties.style.secondary_button_bg"
      class="form-control form-control-lg"
      type="color"
      ></b-form-input>
    </b-input-group>
    <b-input-group class="mb-5">
      <template v-slot:prepend>
        <b-input-group-text >Primary Button Text Color</b-input-group-text>
      </template>
      <b-form-input
      v-model="properties.style.primary_button_color"
      class="form-control form-control-lg"
      type="color"
      ></b-form-input>
    </b-input-group>
    <b-input-group class="mb-5">
      <template v-slot:prepend>
        <b-input-group-text >Secondary Button Text Color</b-input-group-text>
      </template>
      <b-form-input
      v-model="properties.style.secondary_button_color"
      class="form-control form-control-lg"
      type="color"
      ></b-form-input>
    </b-input-group>

    <a
      class="btn btn-light-primary float-right font-weight-bolder mr-1 font-size-lg"
      >Submit</a
    >
  </div>
  <!--end::Card-->
</template>

<script>
export default {
  name: "Tempate1",
  data() {
    return {
      properties: {
        style: {
          primary_color: '',
          secondary_color: '',
          primary_button_bg: '',
          secondary_button_bg: '',
          primary_button_color: '',
          secondary_button_color: ''
        }
      }
    }
  },
  methods: {
    save() {
      //
    },
    cancel() {
      //
    },
  },
};
</script>
